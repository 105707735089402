import Head from 'next/head';
import React from 'react';

export default function Custom404() {
  return (
    <>
      <Head>
        <title key="title">404 Page Not Found | Kentro.io</title>
        <link rel="stylesheet" href="/404.css" />
      </Head>
      <div className="wrap-404">
        <div className="title">404</div>
        <div className="subtitle">page not found</div>
        <div className="content">
          powered by{' '}
          <a href="https://kentro.io" target="_blank" rel="noopener noreferrer">
            Kentro.io
          </a>
        </div>
      </div>
    </>
  );
}
